.ant-layout {
    padding: 0 !important;
    background-color: #fff !important;
  }
  
  .ant-layout-content {
    padding: 0 !important;
    overflow: initial !important;
  }
  
  .ant-menu{
      font-size: 12px !important;
  }
  
  .ant-layout-sider{
      width: 160px !important;
      flex: 0 0 160px !important;
      max-width: 160px !important;
      min-width: 160px !important;
  }
  
  .ant-table {
    font-size: 12px !important;
  }
  
  .ant-table-wrapper{
      padding: 0 12px !important;
  }
  
  .ant-table-body{
      overflow-y: auto !important;
      max-height: initial !important;
  }
  
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
      padding: 8px !important;
  }
  
  .ant-pagination{
      font-size: 12px !important;
  }
  
  .ant-form{
      padding: 0 24px !important;
  }
  
  .ant-form-item-label{
      max-width: 30% !important;
      flex: 0 0 30% !important;
  }
  
  .ant-tag{
      margin-bottom: 4px !important;
  }
  
  .dynamic-form-drawer .ant-drawer-close {
    display: inline-block;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
    position: initial;
  }  